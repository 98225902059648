<template>
  <div>
    <div class="card no-border">
      <div class="script-title">
        <h2>{{ video.title }}</h2>
      </div>
      <div class="card-body">
        <video
          v-bind:src="this.video.video"
          alt
          class="card-video-head video-script"
          controls
          loop
        ></video>
      </div>
      <div class="post-description-container">
        <textarea
          v-model="this.video.script"
          ref="postDescription"
          class="form-input text-area-input w-input post-description"
        ></textarea>
        <span class="copy-text" v-on:click="copy"
          ><i class="fas fa-copy"></i
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    video: Object,
  },
  methods: {
    copy() {
      this.copyText(this.$refs.postDescription); //Custom mixin. Ref is defined in the element e.g. <div ref="text">
      this.$swal.fire({
        title: "Text Copied to Clipboard",
        timer: 1200,
        icon: "success",
        toast: true,
      });
    },
  },
  created() {},
};
</script>
<style scoped>
.card {
  text-align: center;
}
.post-download {
  margin-top: -15px;
}
.video-script {
  width: 240px;
}
.post-description {
  resize: none;
}
.copy-text {
  margin-top: 5px;
  float: right;
  cursor: pointer;
}
.post-description-container {
  margin-bottom: 15px;
}
.script-title {
  height: 108px;
}
</style>
