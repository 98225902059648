<template>
  <div class="home">
    <Navigation />
    <div class="section bg-primary-3">
      <div class="container text-center">
        <div class="row row-split-content">
          <div class="content-width-small" style="text-align: center">
            <div class="vs-title">
            <h1>Video Scripts</h1>
            </div>
            <h3>{{content.title}}</h3>
          </div>
          <div class="content-width-large">
              <video v-bind:src="content.video" alt class="card-video-head" controls playsinline width="512.5"></video>
          </div>
        </div>
      </div>
    </div>
    <div class="section bg-gray-4">
      <div class="container">
        <div class="w-layout-grid grid-thirds card-grid-thirds">
          <VideoScript
            v-for="video in videos"
            :key="video.title"
            :video="video"
            :editable="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import ContentService from "@/services/contentService.js";
import VideoScript from "@/components/VideoScript.vue";
import PageService from "@/services/pageService.js";
export default {
  components: {
    Navigation,
    VideoScript,
  },
  data() {
    return {
      videos: [],
      content: []
    };
  },
  created() {
    var vm = this;
    ContentService.getContent("scripts")
      .then((response) => {
        console.log(response.data.data.scripts);
        vm.videos = response.data.data.scripts.reverse();
        console.log(vm.videos);
      })
      .catch((error) => {
        console.log("There was an error getting videos", error);
      });

       PageService.getPage("video-scripts")
      .then((response) => {
        this.content = response.data.data.fields;
      })
      .catch((error) => {
        console.log("there was an error getting the content", error);
      });
  },
};
</script>
<style scoped>
.vs-title{
      border-bottom: 8px solid #fff;
    margin-bottom: 50px;
}
</style>